/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";

import { rhythm } from "../utils/typography";

const Bio = ({ author }) => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatarGene: file(absolutePath: { regex: "/gene.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      avatarAnton: file(absolutePath: { regex: "/anton.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author
          social {
            twitter
          }
        }
      }
    }
  `);

  // this is site
  // const { author, social } = data.site.siteMetadata;

  let authorName = `Gene Padaliak`;
  let twitter = `genepadaliak`;
  let linkedin = `genevpd`;
  let authorShortName = `Gene`;
  let avatar = data.avatarGene.childImageSharp.fixed;

  if (author === "anton") {
    authorName = `Anton Dosov`;
    twitter = `antondosov`;
    linkedin = `antondosov`;
    authorShortName = `Anton`;
    avatar = data.avatarAnton.childImageSharp.fixed;
  }

  return (
    <div
      style={{
        display: `flex`,
      }}
    >
      <Image
        fixed={avatar}
        alt={authorName}
        style={{
          marginRight: rhythm(1 / 2),
          marginBottom: 0,
          minWidth: 50,
          borderRadius: `100%`,
        }}
        imgStyle={{
          borderRadius: `50%`,
        }}
      />
      <p>
        Posted by <strong>{authorName}</strong>
        <br />
        <a href={`https://www.linkedin.com/in/${linkedin}`}>LinkedIn</a>
        {" |  "}
        <a href={`https://twitter.com/${twitter}`}>Twitter</a>
      </p>
    </div>
  );
};

export default Bio;
