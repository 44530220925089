import React from "react";
import { Link, graphql } from "gatsby";

import Bio from "../components/bio";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { rhythm, scale } from "../utils/typography";

class BlogPostTemplate extends React.Component {
  onInnerContentClick(event, openDialogFn) {
    const action =
      event.target.dataset.action || event.target.parentElement.dataset.action;
    // if (action && action === "open-signup-dialog") {
    //   event.stopPropagation();
    //   event.preventDefault();
    //   openDialogFn();
    // }
  }

  render() {
    const post = this.props.data.markdownRemark;
    const siteTitle = this.props.data.site.siteMetadata.title;
    const { previous, next } = this.props.pageContext;
    const seoImage =
      post.frontmatter.featuredImage &&
      post.frontmatter.featuredImage.childImageSharp.sizes &&
      post.frontmatter.featuredImage.childImageSharp.sizes.src;

    // if empty then Gene
    const author = post.frontmatter.author;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          image={seoImage}
        />
        <h1
          style={{
            marginTop: rhythm(1),
            //marginBottom: 0,
          }}
        >
          {this.props.type}
          {post.frontmatter.title}
        </h1>
        {/* <p
          style={{
            ...scale(-1 / 5),
            display: `block`,
            marginBottom: rhythm(1),
            fontSize: '12px'
          }}
        >
          {post.frontmatter.date}
        </p> */}
        <div dangerouslySetInnerHTML={{ __html: post.html }} />

        <p className="blog-footer">
          <Bio author={author} />
          <a
            href="https://cdn.forms-content.sg-form.com/11ffd500-52f6-11ec-9444-8a405dc6fe92"
            target="_blank"
            className="mdc-button mdc-button--unelevated main-cta"
          >
            Subscribe to Remeet blog
          </a>
        </p>

        {(previous || next) && (
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
        )}
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        author
        featuredImage {
          childImageSharp {
            sizes(maxWidth: 630) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    }
  }
`;
